import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";
import LandingPage from "./pages/Landing";
import Header from "./components/Layout/Header";
import Content from "./components/Layout/Content";
import Footer from "./components/Layout/Footer";
const App: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Times New Roman",
            },
          }}
        >
          <Layout>
            <Header />
            <Content>
              <LandingPage />
            </Content>
            <Footer />
          </Layout>
        </ConfigProvider>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
