import { Layout } from "antd";
import styled from "styled-components";

export default styled(Layout.Header)`
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
  height: 86px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px !important;
  .title {
    color: #fff;
    font-size: 40px;
    margin: 0px;
  }
`;
