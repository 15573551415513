import { ReactNode} from "react";

import StyledHeader from "./styles";
import { Typography } from "antd";

const Header: React.FC<{ children?: ReactNode }> = (props) => {
  return <StyledHeader>

    <Typography.Title level={1} className="title">TheCiftlik</Typography.Title>
  </StyledHeader>;
};

export default Header;
