'use client';

import { ReactNode } from 'react';

import {Typography } from 'antd';


import StyledFooter from './styles';

const Footer: React.FC<{ children?: ReactNode }> = (props) => {
  return (
    <StyledFooter >
      <Typography.Text className='text'>
      © 2024 The Ciftlik. All Rights Reserved.
      </Typography.Text>
    </StyledFooter>
  );
};

export default Footer;
