import { Space, Typography } from "antd";
import StyledRow from "./styles";

const LandingPage: React.FC = () => {
  return (
    <StyledRow>
      <Space direction="vertical">
        <Typography.Title level={2}>Members</Typography.Title>
        <Typography.Text className="text">
          Mehmet Hasat Serinkan <span className="subtext">21901649</span>{" "}
        </Typography.Text>
        <Typography.Text className="text">
          Mehmet Eren Balasar <span className="subtext">22001954</span>
        </Typography.Text>
        <Typography.Text className="text">
          Zeynep Naz Sevim <span className="subtext">22001795</span>{" "}
        </Typography.Text>
        <Typography.Text className="text">
          Ozan Can Gülbaz <span className="subtext">22002019</span>{" "}
        </Typography.Text>
        <Typography.Text className="text">
          Ahmet Emir Boşnak <span className="subtext">22002398</span>{" "}
        </Typography.Text>

        <Typography.Title level={2}>Supervisor</Typography.Title>
        <Typography.Text className="text">
          İbrahim Körpeoğlu{" "}
          <a
            href="http://www.cs.bilkent.edu.tr/~korpe/homepage/index.html "
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        </Typography.Text>

        <Typography.Title level={2}>Innovation Expert</Typography.Title>
        <Typography.Text className="text">
          Metin Emenullahi{" "}
          <a
            href="https://www.linkedin.com/in/metinemenullahi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        </Typography.Text>

        <Typography.Title level={2}>Reports</Typography.Title>
        <Typography.Text className="text">TBD</Typography.Text>

        <Typography.Title level={2}>Github Repos</Typography.Title>
        <Typography.Text className="text">TBD</Typography.Text>
      </Space>
    </StyledRow>
  );
};

export default LandingPage;
